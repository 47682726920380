import React from 'react';

const CLIDDA = () => {
    return(
        <div className="container">
            <h4>Clínica de Detección y Diagnóstico Automatizado (CLIDDA)</h4>
            <div className="row">
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Requisitos</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">CURP impresa</li>
                            <li className="list-group-item">Llenar formato de CLIDDA (Se entrega en la Sección Sindical)</li>
                        </ul>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Resumen</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item text-center">
                                {/* ------- hasta diciembre 2023------- 16 */}
                                <strong>{'Nov. 2018 - Dic. 2024 '}</strong><span className="badge badge-primary badge-pill">{237+62}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CLIDDA;