import React from 'react';

const Lentes = () => {
    return (
        <div className="container">
            <h4>Ayuda de Lentes</h4>
            <div className="row">
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Requisitos</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Factura original a nombre del trabajador</li>
                            <li className="list-group-item">Copia del último comprobante de pago</li>
                            <li className="list-group-item">Copia de credencial de trabajador del HGM</li>
                            <li className="list-group-item">Llenar formato de ayuda de lentes (Se entrega en la Sección Sindical)</li>
                        </ul>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card border-info mb-3">
                        <h6 className="card-header">Resumen</h6>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item text-center">
                                {/* -------hasta diciembre 2023------- */}
                                <strong>{'Nov. 2018 - Dic. 2024 '}</strong><span className="badge badge-primary badge-pill">{5119+1160}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Lentes;