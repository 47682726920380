import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import InfoIcon from '@material-ui/icons/Info';
import TooltipComponent from './Genericos/TooltipComponent';

const Oficializacion = () => {
    const [openInfo, setOpenInfo] = useState(false);
    return(
            <div className="container">
                <h4>Oficialización de Turno</h4>
                <div className="card border-info mb-3">
                    <h5 className="card-header">Requisitos 2025</h5>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Escrito de petición dirigido al Secretario General del CES XIV del CEN del SNTSA <TooltipComponent text="Datos que debe contener el documento"><InfoIcon onClick={() => setOpenInfo(!openInfo)} style={{cursor:'pointer'}} /></TooltipComponent></li>
                        <Collapse in={openInfo} timeout="auto" unmountOnExit>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>{'A) Número de empleado'}</strong></li>
                                <li className="list-group-item"><strong>{'B) Fecha de ingreso a la SSA'}</strong></li>
                                <li className="list-group-item"><strong>{'C) Código y función que realiza'}</strong></li>
                                <li className="list-group-item"><strong>{'D) Horario oficial'}</strong></li>
                                <li className="list-group-item"><strong>{'E) Horario solicitado'}</strong></li>
                                <li className="list-group-item"><strong>{'F) Servicio'}</strong></li>
                                <li className="list-group-item"><strong>{'G) Número telefónico'}</strong></li>
                                <li className="list-group-item"><strong>{'H) Nombre completo y firma del solicitante'}</strong></li>
                            </ul>
                        </Collapse>
                        <li className="list-group-item">Copia de autorización de prorroga de los años 2023, 2024 y 2025</li>
                        <li className="list-group-item">Copia de último comprobante de pago</li>
                        <li className="list-group-item">Copia de credencial de trabajador del HGM</li>
                        <li className="list-group-item">Copia de FUMP o FOMOPE</li>
                    </ul>
                </div>
            </div>
    );
}

export default Oficializacion;